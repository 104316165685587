// @flow

import React from "react";
import {
    withPrivateRoute,
    Layout,
    AdminUserManagementLayout,
    InternalGroups,
} from "@containers";

/**
 * Admin Gebruikers Beheer - Interne Groepen
 */
const AdminInternalGroups = (props: *) => {
    return (
        <Layout {...props}>
            <AdminUserManagementLayout>
                <InternalGroups id="internal-groups" />
            </AdminUserManagementLayout>
        </Layout>
    );
};

export default withPrivateRoute(AdminInternalGroups);
